import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: sessionStorage.getItem('currId') || 1,
    domainImg: 'http://static.parceljet.com/img/',  // 图片路径
    siteCode: 'www',
    siteInfo: {},
    node: {}, // 官网翻译数据
    newsList: {
      newsList_0: {},
      newsList_1: []
    }, // 首页新闻资讯
    bizList: [], // 首页主营业务
    about: {},  // 首页公司简介
    homeList: [], // 首页横幅
    navList: [],  // 同步菜单
    businessCategory: [], //主营业务菜单
    newsCategory: [], //新闻资讯菜单
    relationList: [], //首页联系我们
    languageType: sessionStorage.getItem('languageType') || 'zh', // 中英文切换
    trackNum: '', // 轨迹单号
    liftNum: 1, // 公司简介展示层级
    liftAboutNum: 1, // 公司简介展示层级
    liftBusinessNum: 1, // 物流展示层级
  },
  mutations: {
    setLiftBusinessNum (state, index) {
      state.liftBusinessNum = index
    },
    setLift (state, index) {
      state.liftAboutNum = index
    },
    getTrackNum (state, index) {
      state.trackNum = index
    },
    getLanguageType (state, index) {
      state.languageType = index
      sessionStorage.setItem('languageType', index)
    },
    setId (state, id) {
      state.id = id
    },
    getsiteCode (state, str) {
      state.siteCode = str
    },
    getSiteInfo (state, obj) {
      state.siteInfo = obj
    },
    getNode (state, obj) {
      state.node = obj
    },
    getNewsList (state, obj) {
      state.newsList = obj
    },
    getBizList (state, arr) {
      state.bizList = arr
    },
    getAbout (state, obj) {
      state.about = obj
    },
    getHomeList (state, arr) {
      state.homeList = arr
    },
    getNavList(state, arr) {
      state.navList = arr
    },
    getBusinessCategory(state, arr) {
      state.businessCategory = arr
    },
    getNewsCategory(state, obj) {
      state.newsCategory = obj
    },
    getRelationList(state, arr) {
      state.relationList = arr
    }
  },
  modules: {}
});
